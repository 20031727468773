<template>
  <component
    :is="choosenComponent"
    v-if="choosenComponent"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import ModalErrorCannotPurchaseDifferentProgram from '@/components/widgets/modal-info/modal-error-cannot-purchase-different-program.vue'
import ModalErrorEmailAlreadyRegistered from '@/components/widgets/modal-info/modal-error-email-already-registered.vue'
import ModalConfirmExitTransactionPage from '@/components/widgets/modal-info/modal-confirm-exit-transaction-page.vue'
import ModalInfoNotification from '@/components/widgets/modal-info/modal-notification.vue'

export default {
  name: 'ModalInfo',
  components: { ModalErrorEmailAlreadyRegistered },
  props: {
    for: {
      type: String,
      required: true,
      validator(value) {
        return [
          'errorCannotPurchaseDifferentProgram',
          'errorEmailAlreadyRegistered',
          'confirmExitTransactionPage',
          'infoNotitifcation',
        ].includes(value)
      },
    },
  },
  computed: {
    choosenComponent() {
      switch (this.for) {
        case 'errorCannotPurchaseDifferentProgram':
          return ModalErrorCannotPurchaseDifferentProgram
        case 'errorEmailAlreadyRegistered':
          return ModalErrorEmailAlreadyRegistered
        case 'confirmExitTransactionPage':
          return ModalConfirmExitTransactionPage
        case 'infoNotitifcation':
          return ModalInfoNotification
        default:
          return false
      }
    },
  },

}
</script>
